import { render, staticRenderFns } from "./Unauthenticated.vue?vue&type=template&id=15b857a4&scoped=true&"
var script = {}
import style0 from "./Unauthenticated.vue?vue&type=style&index=0&id=15b857a4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b857a4",
  null
  
)

export default component.exports